module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Interoke Digital","short_name":"Interoke Digital","start_url":"/","background_color":"#FDB813","theme_color":"#FDB813","display":"standalone","icon":"src/assets/img/icon-grey.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
